import { Client } from '@eql-ai/typescript-types';

const CoreCustomerDefault: Partial<Client> = {
    integration_type: 'int_type_4',
    triage_path: 'Indirect',
    link_active_duration: 1,
    email: 'info@eql.ai',
    validated: true,
    outcome_wording_urgent_care: 'testing',
    require_oxford: false,
    outcome_wording_f2f: 'test',
    phio_agent: 'phio_access',
    id: '',
    outcome_wording_no_tx: 'testing framework',
    third_party_f2f_enabled: true,
    member_verification_enabled: false,
    require_api: true,
    clinical_services_enabled: true,
    capture_period: 1,
    require_keele_start: false,
    is_internal_customer: true,
    phone: '+44 7502 374958',
    terminate_early: false,
    outcome_wording_self_management: 'test',
    name: 'EQL',
    agreement_type: 'ag_type_3',
    phio_agent_version: 1,
};

export default CoreCustomerDefault;
